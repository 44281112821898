import { __awaiter } from "tslib";
import attachAccessibilityEvents from '@wework/ray-core/src/global/js/accessibility';
import setUpAccessibilitySkipTo, { SKIP_TO_MAIN_CONTENT_ATTRIBUTE } from '../Shared/accessibility-skip-to';
import Footer from '../Shared/footer';
import Navbar from '../Shared/navbar';
import { onContentLoaded } from '../Shared/utils';
import { updateScrollbarWidthVar } from '../Shared/default-scrollbar-width';
window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
onContentLoaded(() => __awaiter(void 0, void 0, void 0, function* () {
    updateScrollbarWidthVar();
    setUpAccessibilitySkipTo(SKIP_TO_MAIN_CONTENT_ATTRIBUTE);
    attachAccessibilityEvents();
    yield Navbar.create();
    Footer.create();
}));
